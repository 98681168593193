
//INFO USER
.user {
  .panel();
  .user-header {
    .panel-header();
    height: 50px;
    line-height: 50px;
    padding: 0 @base-margin;
  }
  .user-content {
    padding: @base-margin;
    .panel-content();
  }
}

//DEVICE LIST
.user-row {
  border-bottom: 1px solid @background-light;
  padding: @base-margin 0;
  font-weight: 300;
  font-size: 15px;
  &:last-child {
    border-bottom: 0;
  }
  B {
    font-weight: 700;
  }
  A {
    color: @dark;
    text-decoration: underline;
  }
  .user-name {
    .make-xs-column(12);
    .make-md-column(6);
  }
  .user-action {
    .make-xs-column(12);
    .make-md-column(6);
    @media (min-width: @screen-md) {
      text-align: right;
    }
  }
}

//ADD NEW USER
.add-new-user {
  .panel();
  .make-row();
  padding: @base-margin;
  text-align: center;
  .form-group {
    width: 100%;
    overflow: auto;
    @media (min-width: @screen-md) {
      width: 80%;
      float: left;
    }
    input {
      height: 40px;
      line-height: normal;
      padding: 12px 12px;
      vertical-align: middle;
      width: 100%;

      &:focus {
        outline: none;
        box-shadow: none;
        background-color: @input-color;
        border: 1px solid @input-focus-border;
      }
      @media (min-width: @screen-md) {
        width: 90%;
        float: right;
        }
      }
    label {
      margin-bottom: 10px;
      @media (min-width: @screen-md) {
        float: left;
        margin: 7px 0 0 0;
      }
    }
  }
  .btn {
    width: 120px;
    margin: 10px auto 0 auto;
    .img-retina("../images/plus.png"; "../images/plus@2x.png"; 7px; 8px);
    background-repeat: no-repeat;
    background-position: 84% center;
    &:hover {
      background-color: @brand-primary;
      color: #fff;
    }
    @media (min-width: @screen-md) {
      margin: 0px 0 10px 5px;
      width: 19%;
      float: left;

    }
  }
}

//LOGIN PAGE
.login-box {
  background-color: #fff;
  padding: 20px;
  width: 100%;
  margin-bottom: 0;
  @media (min-width: @screen-md) {
      width: 50%;
      margin: 3% auto 0 auto;
      padding: 50px 70px;
    }
  label {
    font-size: 15px;
    font-weight: normal;
  }
  .btn-primary {
      width: 100%;
      @media (min-width: @screen-md) {
        width: auto;
      }
    }
    A {
      color: @background-header;
      text-decoration: underline;
      padding-top: 20px;
      display: block;
    }
  .back {
      font-size: 14px;
      font-family: "Roboto", sans-serif;
      padding-top: 7px;
    }
  .buttons-set {
      text-align: center;
      margin-top: 30px;
    }
  }
.login-header {
  border-bottom: 1px solid #ccc;
  overflow: auto;
  width: 100%;
  padding-bottom: @base-margin * 2;
  margin-bottom: @base-margin * 2;
  text-align: center;
  .login-title {
    h1 {
      font-family: 'Roboto Condensed', sans-serif;
      margin: 0;
      font-size: 30px;
    }
    h4 {
      font-family: 'Roboto Condensed', sans-serif;
      color: #999;
      margin: 0;
      font-weight: normal;
      font-size: 1em;
    }
  }
  @media (min-width: @screen-md) {
    text-align: left;
    img {
      float: left;
      margin-right: 40px;
    }
  }
}

//FORM
select {
  outline: none;
  border: none !important;
  .box-shadow(none) !important;
}

button.btn, a.btn {
  font-family: 'Roboto Condensed', sans-serif;
  text-transform: uppercase;
  padding: 10px 24px;
  border: none;
  height: 40px;
}

button.btn-primary {
  background-color:  @background-header;
  border: none;
}

.has-error .control-label {
  color: #000;
}

.help-block strong {
  font-weight: normal;
  font-size: 13px;
}

.form-control {
  background: #e4e2e2;
  border: none;
  color: #212121;
  line-height: normal;
}

//user info

//content
.user-info, .user-device {
  .panel();
  margin: 0 auto @base-margin;
    @media (min-width: @screen-md) {
      width: 80%;
    }
    .user-info-header, .user-device-header {
    .panel-header();
    padding:0  @base-margin;
      height: 50px;
      line-height: 50px;
  }
  .user-info-content, .user-device-content {
    .panel-content();
    padding: @base-margin;
    P {
      font-weight: 300;
    }
    .psw-link {
      color: @text-color;
      font-family: "Roboto condensed", sans-serif;
      text-decoration: underline;
    }
    .psw-box {
      display: none;
      padding: @base-margin 0;
    }
    .btn-container {
      text-align: center;
    }
  }
}
