//DEVICE LIST

//header
.device-name {
    .make-xs-column(12);
    .make-sm-column(3);
    font-weight: 500;
    padding: 15px 0 0 0;
    text-align: center;
    position: relative;

    @media (min-width: @screen-sm) {
      padding: 0 0 0 15px;
      text-align: left;
    }
    span {
      position: relative;
      padding-right: 20px;
      &::after {
        content: "";
        .img-retina("../images/edit.png"; "../images/edit@2x.png"; 10px; 10px);
        background-repeat: no-repeat;
        display: block;
        height: 10px;
        width: 10px;
        position: absolute;
        top: 6px;
        right: 0px;
      }
    }
    .form-control {
      margin: 0px;
      background-color: @background-header;
      color: #fff;
      font-size: 17px;
      border: none;
      box-shadow: none;
      outline: none;
      height: 40px;
      outline: 0;
      border-bottom: 1px solid #fff;
      position: relative;

      &:focus {
        background-color: @background-header;
        border: none;
        border-bottom: 1px solid #fff;
      }

      @media (min-width: @screen-sm) {
        margin: 0px 0 0 -8px;
      }

      &::placeholder {
        text-align: left;
        color: #fff !important;
        font-family: "Roboto", sans-serif !important;
        font-weight: 300;
        opacity: 1;
        line-height: 40px;
        vertical-align: middle;
      }

      &::-webkit-input-placeholder  {
        text-align: left;
        color: #fff !important;
        font-family: "Roboto", sans-serif !important;
        font-weight: 300;
        opacity: 1;
        line-height: 40px;
        vertical-align: middle;
      }

      &:-moz-placeholder  {
        text-align: left;
        color: #fff !important;
        font-family: "Roboto", sans-serif !important;
        font-weight: 300;
        opacity: 1;
        line-height: 40px;
        vertical-align: middle;
      }

      &::-moz-placeholder  {
        text-align: left;
        color: #fff;
        font-family: "Roboto", sans-serif;
        font-weight: 300;
        opacity: 1;
        line-height: 40px;
        vertical-align: middle;
      }

      &:-ms-input-placeholder {
        text-align: left;
        color: #fff !important;
        font-family: "Roboto", sans-serif !important;
        font-weight: 300;
        opacity: 1;
        line-height: 40px;
        vertical-align: middle;
      }

    }
  }

.device-lastupdate{
  .make-xs-column(12);
  .make-sm-column(6);
  font-size: 12px;
  padding: 5px 0 15px 0;
  text-align: center;
  font-weight: 300;
  font-family: Roboto, sans-serif;
  @media (min-width: @screen-sm) {
    padding: 0 0;
  }
}

.button-detail {
  .make-xs-column(12);
  .make-sm-column(3);
  background-color: @dark;
  color: #fff;
  text-decoration: none;
  padding: 15px 0;
  text-align: center;
  background-position: 3% center;
  background-repeat: no-repeat;
  .img-retina("../images/bars.png"; "../images/bars@2x.png"; 25px; 17px);
  @media (min-width: @screen-sm) {
    padding: 0 0;
  }
  &:hover, &:active {
    background-color: @brand-primary;
    color: #fff;
    text-decoration: none;
  }
  span {
    .img-retina("../images/angl-left-white.png"; "../images/angl-left-white@2x.png"; 6px; 9px);
    .img-retina("../images/angl-right-white.png"; "../images/angl-right-white@2x.png"; 6px; 9px);
    background-repeat: no-repeat;
    background-position: 94% center;
    display: block;
  }
  &.back {
    background-image: none;
    span {
      .img-retina("../images/angl-left-white.png"; "../images/angl-left-white@2x.png"; 6px; 9px);
      background-position: 4% center;

    }
  }
}

//content
.device {
  .panel();
  .device-header {
    .panel-header();
  }
  .device-content {
    .panel-content();
  }
}

.device-preview {
  .make-xs-column(6);
  .make-sm-column(3);
  text-align: center;
  font-family: "Roboto Condensed", sans-serif;
  border-right: 1px solid @background-light;
  padding: @base-margin*2 0 @base-margin 0;
  height: 180px;
  &.detail {
    .make-xs-column(4);
    .make-sm-column(3);
  &.two {
    .make-xs-column(6);
    .make-sm-column(4);
  }
  &.one {
    .make-xs-column(12);
    .make-sm-column(6);
  }
  @media (min-width: @screen-sm) {
    height: auto;
    min-height: 180px;
    &.one, &.two {
      height: auto;
      min-height: 180px;
    }
  }
  }
  @media (min-width: @screen-sm) {
    height: auto;
    min-height: 180px;
  }

  &:last-child {
    border-right: none;
  }
}

.general-info {
  background-color: @background-light;
  padding: @base-margin 0;
  .make-xs-column(12);
  .make-sm-column(3);

  IMG {
    margin: 20px 0;
  }
  font-size: 14px;
  b {
    display: block;
    font-size: 22px;
    line-height: normal;
  }
}

.detailed-info {
  .make-xs-column(12);
  background-color: @background-light;
  padding: @base-margin 0;
  @media (min-width: @screen-sm) {
    &.one {  .make-sm-column(6); min-height: 180px; }
    &.two {  .make-sm-column(4); min-height: 180px; }
    .make-sm-column(3);
    min-height: 180px;
  }
  img {
    margin: 40px 30px 0px 30px;
    float: left;
  }
  .info {
    float: left;
    text-align: left;
    font-size: 15px;
    margin-top: 30px;
    font-family: "Roboto Condensed", sans-serif;
    b {
      display: block;
      font-size: 17px;
      line-height: normal;
    }
  }
}

.show_as_first {
  font-size: 15px;
  padding: 8px 0;
  display: block;
  input {
    margin-right:5px ;
  }
}

.value {
   font-size: 35px;
  @media (min-width: @screen-sm) {
    font-size: 65px;
  }
}

.device-label {
  font-size: 15px;
  font-weight: 300;
  color: @background;
  @media (min-width: @screen-sm) {
    margin-top: -10px;
    font-size: 21px;
  }
}

.device-controls {
  margin-bottom: @base-margin;
  background-color: #fff;
  font-weight: 300;
  .make-row();
  .control {
    .make-sm-column(4);
    border-right: 1px solid @background-light;
    padding: 20px;
    text-align: center;
    @media (min-width: @screen-sm) {
      min-height: 180px;
    }
    h3{
      font-weight: 300;
      margin-top: 0;
      font-size: 22px;
      margin-bottom: 20px;
    }
    .hint {
      font-size: 13px;
      padding-top: 5px;
    }
    .control-container {
      width: 180px;
      margin: 0 auto;
      overflow: auto;
      .input-text {
        background-color: #e4e2e2;
        box-shadow: 0;
        border: none;
        height: 50px;
        float: left;
        width: 70px;
        padding: 7px;
        &:focus {
          outline: 0;
          border: 1px solid #999;
        }
      }

      .styled-select {
        float: left;
      }
      .btn {
        float: left;
        height: 50px;
        width: 100px;
        margin: 0;
        text-align: left;
        .img-retina("../images/update.png"; "../images/update@2x.png"; 17px; 15px);
        background-position: 93% center;
        background-repeat: no-repeat  ;
        &:hover, &:active {
          background-color: @brand-primary;
          color: #fff;
        }
      }
    }
  }
}

//SWITCH
.onoffswitch {
  position: relative;
  width: 90px;
  margin: 0 auto;
  text-align: center;
  -webkit-user-select:none;
  -moz-user-select:none;
  -ms-user-select: none;
  font-family: 'Roboto Condensed', sans-serif;
}
.onoffswitch-checkbox,
.onoffswitch-checkbox2 {
  display: none;
}
.onoffswitch-label,
.onoffswitch-label2 {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border-radius: 0px;
  font-weight: normal;
}
.onoffswitch-inner,
.onoffswitch-inner2 {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}
.onoffswitch-inner .on,
.onoffswitch-inner .off,
.onoffswitch-inner2 .auto,
.onoffswitch-inner2 .manu {
  display: block;
  float: left;
  width: 50%;
  height: 50px;
  padding: 0;
  line-height: 50px;
  font-size: 14px;
  color: @dark;
  text-align: left;
  box-sizing: border-box;
}
.onoffswitch-inner .on,
.onoffswitch-inner2 .auto {
  content: "ON";
  padding-left: 10px;
  background-color: @on;
  color: #FFFFFF;
}
.onoffswitch-inner .off,
.onoffswitch-inner2 .manu{
  content: "OFF";
  padding-left: 10px;
  background-color: #EEEEEE; color: #999999;
  text-align: left;
}
.onoffswitch-switch,
.onoffswitch-switch2 {
  display: block;
  width: 25px;
  margin: 0px;
  background: @dark;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  border-radius: 0px;
  transition: all 0.3s ease-in 0s;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner,
.onoffswitch-checkbox2:checked + .onoffswitch-label2 .onoffswitch-inner2 {
  margin-left: 0;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch,
.onoffswitch-checkbox2:checked + .onoffswitch-label2 .onoffswitch-switch2 {
  right: 0px;
}