
//CSS VARIABLES

@background-light: #e9e9e9;
@background: #5b747f;
@brand-primary: #008cc8;
@background-header: #2a3b46;
@dark: #1a252c;
@over: #a8d973;
@on: #8ab160;
@state-danger-text: #c81f27;
@input-color: #e4e2e2;
@border: #ccc;
@input-focus-border: @dark;
@base-margin: 15px;
@border-radius-base: 0;
@text-color: #212121;

html, body {
  background-color: @background;
  font-family: 'Roboto', sans-serif;
  font-size: 17px;
  height: 100%;
  min-height: 100%;
}

.align-right { text-align: right;  }
.align-left  { text-align: left;  }

button.btn-default, button.btn-default:focus {
  background-color: @dark;
  color: #fff;
  border: none;
}

//HEADER
header {
  background: @background-light;
  font-weight: 300;
  height: 100px;
  .box-shadow(@shadow: -1px 6px 5px rgba(0,0,0,.21));
  margin-bottom: @base-margin;

  .logo {
    .make-sm-column(3);
    .make-xs-column(4);

    IMG {
      margin-top: 13px;
    }
  }
  .dropdown.account {
    .make-sm-column-push(6);
    .make-sm-column(3);
    .make-xs-column(7);
    padding: 0;
    float: right;
    @media (min-width: @screen-sm) {
      float: left;
    }
  }

  .container {
    padding: 0;
    @media (min-width: @screen-sm) {
      padding: 0 15px;
    }
  }
}

//dropdown menu account
.dropdown.account {
  A {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }
  .dropdown-toggle {

    display: block;
    height: 100px;
    line-height: 100px;
    text-align: center;
    &::after {
      border: none;
    }
    span.arrow {
      .img-retina("../images/angl-down.png"; "../images/angl-down@2x.png"; 8px; 5px);
      padding-right: 20px;
      background-repeat: no-repeat;
      background-position: center right;
    }
    span.icon {
      .img-retina("../images/account-img.png"; "../images/account-img@2x.png"; 20px; 20px);
      background-repeat: no-repeat;
      background-position: 25% center;
      padding: 5px 0px 5px 50px;
    }
    &:hover {
      background-color: @over;
    }
  }
  &.open {
    .dropdown-toggle {
      background-color: @over;
    }
  }
  .dropdown-menu {
      border: none;
      width: 100%;
      margin: 0;
      padding: 0;
    > LI {
        text-align: center;
        border-bottom: 1px solid #ccc;
        &:last-child { border-bottom: 0;}
      > A {
        display: block;
        height: 80px;
        line-height: 80px;
        font-weight: 300;
        font-size: 17px;
        padding: 0;
        background-repeat: no-repeat;
        background-position: 70% center;
        .img-retina("../images/angl-right.png"; "../images/angl-right@2x.png"; 5px; 8px);
      }
    }
  }
}


//FOOTER
footer {
  background-color: @background-light;
  font-size: 15px;
  font-weight: 300;
  margin-bottom: 20px;

  A {
    color: @brand-primary;
  }
}

.footer-left {
  display: none;
  @media (min-width: @screen-md) {
    display: block;
    .make-md-column(3);
    > IMG {
      margin-top: 5px;
    }
  }
}

.footer-right {
  .make-xs-column(12);
  .make-md-column(9);
  text-align: center;

  ul {
    list-style-type: none;
    padding-top: 10px;
    padding-left: 0;
    li {
      padding: 10px 0;
      display: block;
    }
  }
  @media (min-width: @screen-sm) {
      height: 80px;
      line-height: 80px;
      text-align: right;
    ul {
      float: right;
      padding: 0px;
      margin: 0;
      li {
        float: left;
        padding: 0;

        A {
          border-right: 1px solid #ccc;
          padding: 0 20px;
        }
        &:last-child {
          border: none;
        }
      }
    }
  }
}

.lang.dropup {
  > .dropdown-toggle {
    background-color: lighten(@background, 40%);
    border: none;
    padding: 8px 40px 8px 8px;
    margin-top: -10px;
    font-family: "Roboto", sans-serif;
    font-size: 15px;
    color: #212121;
    .img-retina("../images/angl-down.png"; "../images/angl-down@2x.png"; 8px; 5px);
    background-repeat: no-repeat;
    background-position: 90% center;
    text-align: left;
    text-transform: none;
    font-weight: 300;
    outline: none;
    &:after {
      border: none;
      display: none;
    }
  }
  &.open  > .dropdown-toggle {
    background-color: lighten(@background, 40%);
  }
  ul {
    background-color: lighten(@background, 40%);
    margin-bottom: 20px;
    left: 50%;
    margin-left: -80px;
    border: none;
    padding: 0;
    > li {
      border: none;
      float: none;
      padding: 0;
      text-align: center;
      width: 100%;
      > A {
        padding: 8px;
      }
    }
  }
  @media (min-width: @screen-sm) {
    margin-left: 20px;
    .dropdown-menu {
      margin-bottom: -10px;
      right: 0;
      left: auto;
      > li {
        text-align: left;
      }
    }
  }

  button.btn-disabled {
    cursor: default;
    background-image: none;
    text-align: center;
    padding: 8px 20px;
  }
}

.empty {
  background: #fff;
  @media (min-width: @screen-md) {
    background-color: @background;
  }
  footer {
    background: #fff;
    border-top: 1px solid @border;
    position: relative;
    @media (min-width: @screen-sm) {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      margin: 0;
    }
  }
}

//PANEL
.panel {
  margin-bottom: @base-margin;
  background-color: #fff;
  .panel-header {
    background-color: @background-header;
    color: #fff;
    .make-row();
    font-family: "Roboto Condensed", sans-serif;
      @media (min-width: @screen-sm) {
        height: 50px;
        line-height: 50px;
      }
    }
  .panel-content {
    background-color: #fff;
    .make-row();
  }
}

.styled-select select {
  background: transparent;
  width: 120px;
  padding: 5px;
  font-size: 16px;
  line-height: 1;
  border: 0;
  border-radius: 0;
  height: 50px;
  -webkit-appearance: none;
  padding-left: 10px;
}

.styled-select {
  background-color: #e4e2e2;
  width: 70px;
  height: 50px;
  display: inline-block;
  overflow: hidden;
  border: none;
  .img-retina("../images/angl-down.png"; "../images/angl-down@2x.png"; 8px; 5px);
  background-repeat: no-repeat;
  background-position: 85% center;
}


input.form-control:focus, select:focus {
  outline: none;
  box-shadow: none;
  background:  @input-color;
  border: 1px solid @input-focus-border;
}

button.btn:focus {
  outline: none;
  box-shadow: none;
}

input.form-control {

  &::placeholder {
    text-align: left;
    color: @text-color !important;
    font-family: "Roboto", sans-serif !important;
    font-weight: 300;
    opacity: 1;
    line-height: normal;
    vertical-align: middle;
  }

  &::-webkit-input-placeholder  {
    text-align: left;
    color: @text-color !important;
    font-family: "Roboto", sans-serif !important;
    font-weight: 300;
    opacity: 1;
    line-height: normal;
    vertical-align: middle;
  }

  &:-moz-placeholder  {
    text-align: left;
    color: @text-color !important;
    font-family: "Roboto", sans-serif !important;
    font-weight: 300;
    opacity: 1;
    line-height: normal;
    vertical-align: middle;
  }

  &::-moz-placeholder  {
    text-align: left;
    color: @text-color;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    opacity: 1;
    line-height: normal;
    vertical-align: middle;
  }

  &:-ms-input-placeholder {
    text-align: left;
    color: @text-color !important;
    font-family: "Roboto", sans-serif !important;
    font-weight: 300;
    opacity: 1;
    line-height: normal;
    vertical-align: middle;
  }
}

LABEL {
  color: @background;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 300;
}

.privacy {
  font-size: 10px;
}

.privacy footer {
  background: #fff;
  border-top: 1px solid #ccc;
  position: relative;
  margin-bottom: 0px;
}